import { useState } from 'react';  // Import useState hook
import styles from './Teachers.module.css';
import BackgroundObject from './BackgroundObject';
import FreeLessonPopup from './FreeLessonPopup';

export default function Teachers() {
    const [showPopup, setShowPopup] = useState(false); // Add state to manage pop-up visibility

    const togglePopup = () => {
        setShowPopup(!showPopup); // Toggle pop-up visibility
    };

    // Teacher Switcher
    const teachers = {
        Male: {
            id: 'Male',
            name: 'You?',
            bio: 'Send your resume to chasefluency.com@gmail.com',
            regalia: ['IELTS / TOEFL', 'Experience'],
            photo: '/assets/teachers/Male.jpg',
        },

        Female: {
            id: 'Female',
            name: 'You?',
            bio: 'Send your resume to chasefluency.com@gmail.com',
            regalia: ['IELTS / TOEFL', 'Experience'],
            photo: '/assets/teachers/Female.jpg',
        },

        Chase: {
            id: 'Chase',
            name: 'Chase Deimos',
            bio: 'a teacher whose students achieved 8.0 on the IELTS test',
            regalia: ['HSK 5', '300+ students'],
            photo: '/assets/teachers/TeacherChase.png', // Direct path from the public folder
        },

        Rosette: {
            id: 'Rosette',
            name: 'Rosette Jin',
            bio: 'an ethnical Chinese living in New York, makes movies',
            regalia: ['TOEFL 100', 'Native Chinese Speaker'],
            photo: '/assets/teachers/TeacherRosette.JPG', // Direct path from the public folder
        },
    };

    const [currentTeacher, setCurrentTeacher] = useState(teachers.Chase);

    const handleTeacherSwitch = (event) => {
        setCurrentTeacher(teachers[event.target.id]);
    };

    return (
        <div className={styles.teachersSectionContainer}>
            <div className={styles.teachersSectionContent}>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectRings} 
                        src={require('./assets/objects/rings.png')} 
                        scrollFactor={-0.02}
                    />
                </div>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectEyescream} 
                        src={require('./assets/objects/eyescream.png')} 
                        scrollFactor={-0.03}
                    />
                </div>


                <div className={styles.teachersWindow}>

                    <div className={styles.teachersWindowLeft}>
                        <h1>Learn From The Best</h1>
                        <button onClick={togglePopup}>Free Lesson</button>

                        <div className={styles.switchesWrapper}>

                            {/* Chase Teacher Switch */}
                            <div 
                                className={styles.switch} 
                                onClick={handleTeacherSwitch} 
                                id='Chase'
                                style = {{
                                    backgroundColor: 'violet',
                                    backgroundImage: `url(${teachers.Chase.photo})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    border: currentTeacher.id === 'Chase' ? '1.5px solid #F180EF' : 'none', // Red border if selected
                                }}
                            ></div>

                            {/* Rosette Teacher Switch */}
                            <div 
                                className={styles.switch} 
                                onClick={handleTeacherSwitch} 
                                id='Rosette'
                                style = {{
                                    backgroundColor: 'violet',
                                    backgroundImage: `url(${teachers.Rosette.photo})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    border: currentTeacher.id === 'Rosette' ? '1.5px solid #F180EF' : 'none', // Red border if selected
                                }}
                            ></div>

                            {/* Male Teacher Switch */}
                            <div 
                                className={styles.switch} 
                                onClick={handleTeacherSwitch} 
                                id='Male'
                                style = {{
                                    backgroundColor: 'violet',
                                    backgroundImage: `url(${teachers.Male.photo})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    border: currentTeacher.id === 'Male' ? '1.5px solid #F180EF' : 'none', // Red border if selected
                                }}
                            ></div>

                            {/* Female Teacher Switch */}
                            <div 
                                className={styles.switch} 
                                onClick={handleTeacherSwitch} 
                                id='Female'
                                style = {{
                                    backgroundColor: 'violet',
                                    backgroundImage: `url(${teachers.Female.photo})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    border: currentTeacher.id === 'Female' ? '1.5px solid #F180EF' : 'none', // Red border if selected
                                }}
                            ></div>

                        </div>
                    </div>

                    <div className={styles.teachersWindowMiddle}>
                        <div 
                            className={styles.teacherPhoto}
                            style={{
                            backgroundImage: `url(${currentTeacher.photo})`,
                            backgroundColor: '#F7D8FF',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            }} 
                        ></div>
                    </div>

                    <div className={styles.teachersWindowRight}>
                        <h2 className={styles.teacherName}>{currentTeacher.name}</h2>
                        <p className={styles.teacherBio}>
                            {currentTeacher.bio}
                        </p>
                        <div className={styles.teacherRegaliaWrapper}>
                            {currentTeacher.regalia.map((item, index) => (
                                <div key={index} className={styles.teacherRegalia}>{item}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <FreeLessonPopup showPopup={showPopup} togglePopup={togglePopup}/>
        </div>
    );
}
