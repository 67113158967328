import { useState, useEffect } from 'react';
import styles from './BackgroundObject.module.css'

export default function BackgroundObject(props) {
    const [scrollPosition, setScrollPosition] = useState(0);

    // Track scroll position
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Calculate transformation based on scroll and position
    const calculateTransform = (scrollPos, factorY) => {
        const scrollTransformY = scrollPos * factorY;
        return `translateY(${scrollTransformY}vw)`;
    };

    return (   
            <img 
                className={styles.bgObject + ' ' + props.className} 
                src={props.src} 
                style={{ transform: calculateTransform(scrollPosition, props.scrollFactor) }}
            />
    )
}