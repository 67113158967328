import { useState } from 'react';
import styles from './Navbar.module.css';
import logo from './assets/logo_blacktype.svg';
import globe from './assets/icons/globe.png';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className={styles.navBarContainer}>
        <div className={styles.navBarContent}>
            
            <div className={styles.logo} src={logo}></div>

            <nav className={`${styles.navLinks} ${isOpen ? styles.open : ''}`}>
                <ul className={styles.mainNavigation}>
                    <li><a href='#'>Home</a></li>
                    <li><a href='#'>Flash Cards</a></li>
                    <li className={styles.dropdown}>
                        <a href='#'>Level Test</a>
                        <ul className={styles.testOptions}>
                            <li><a href='#'>English</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>

            <aside>
                <ul className={`${styles.asideLinks} ${isOpen ? styles.open : ''}`}>
                    <li className={styles.dropdown}>
                        <a className={styles.signUpLink} href='#'>Sign Up</a>
                        <ul className={styles.signupOptions}>
                            <li><a href='#'>I'm a Teacher</a></li>
                            <li><a href='#'>I'm a Student</a></li>
                        </ul>
                    </li>

                    <li><a className={styles.logInLink} href='#'>Log In</a></li>

                    <li className={styles.dropdown}>
                        <li>
                            <div className={styles.languages}>
                                <img className={styles.globe} src={globe}/>
                                <a href='#'>English</a>
                            </div>
                        </li>
                        <ul className={styles.languageOptions}>
                            <li><a href='#'>中文</a></li>
                            <li><a href='#'>Русский</a></li>
                        </ul>

                    <div 
                    className={`${styles.navHamburger} 
                    ${isOpen ? styles.open : ''}`}
                    onClick={toggleMenu}>
                        <div className={styles.navHamburgerBar}></div>
                        <div className={styles.navHamburgerBar}></div>
                        <div className={styles.navHamburgerBar}></div>
                    </div>

                    </li>
                </ul>
            </aside>
        </div>
        </div>
    );
}


                
                