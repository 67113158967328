import React from 'react';
import styles from './FreeLessonPopup.module.css'; // Assuming your CSS module is named this
import sign from './assets/sign.svg'; // Importing the sign image

export default function FreeLessonPopup({ showPopup, togglePopup }) {
    
    return (
        <>
            {/* Overlay background and pop-up */}
            <div className={`${styles.overlay} ${showPopup ? styles.overlayVisible : ''}`}></div>
            <div className={`${styles.popup} ${showPopup ? styles.popupVisible : ''}`}>
                {/* Close Button (cross icon) */}
                <div className={styles.closeIcon} onClick={togglePopup}>&times;</div>
                
                {/* Pop-up content */}
                <div className={styles.popupContent}>
                    <div className={styles.popupTop}>
                        <h2 className={styles.popupTitle}>
                            Sign up for a fr
                            <span className={styles.letterSpacing}>
                                e e
                            </span> demo
                        </h2>
                        <img src={sign} className={styles.popupSign} alt="Sign" />
                    </div>

                    <div className={styles.popupMain}>
                        <div className={styles.popupLeftSide}>    
                            <div className={styles.steps}>
                                {/* Left section with steps */}
                                <div className={styles.stepItem}>
                                    <span className={styles.circle}></span>
                                    <p>Meet your tutor</p>
                                </div>

                                <div className={styles.stepItem}>
                                    <span className={styles.circle}></span>
                                    <p>Evaluate lesson format</p>
                                    <div className={styles.lineTop}></div>
                                </div>

                                <div className={styles.stepItem}>
                                    <span className={styles.circle}></span>
                                    <p>Get a personalized learning program</p>
                                    <div className={styles.lineTop}></div>
                                </div>
                            </div>
                        </div>

                        {/* Form section */}
                        <div className={styles.formSection}>
                            <form className={styles.form}>
                                <input type="text" name="name" placeholder="Name" className={styles.inputField} />
                                <input type="tel" name="phone" placeholder="+86 ___ ____ ____" className={styles.inputField} />
                                <input type="email" name="email" placeholder="your@email.com" className={styles.inputField} />
                                <button type="submit" className={styles.submitButton}>I want my free lesson</button>
                            </form>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    );
}
