import styles from './Courses.module.css'
import BackgroundObject from './BackgroundObject.js'

export default function Courses() {
    return (
        <div className={styles.coursesSection}>
            <div className={styles.coursesSectionContent}> 

                <h1 className={styles.coursesSectionTitle}>Our Courses</h1>

                <div className={styles.coursesWrapper}>

                    <div className={styles.courseBox + ' ' + styles.course1}>
                        <div className={styles.courseInterfaceWrapper}>
                            <h2 className={styles.courseTitle}>English Upgrade</h2>
                            <p className={styles.courseSubtitle}>Go up one level in 6 months</p>
                            <div className={styles.circleIcon}>
                                <span className={styles.circleText}>More Info</span>
                                <div className={styles.arrow}></div>  
                            </div>
                        </div>
                    </div>
                    
                    <div className={styles.courseBox + ' ' + styles.course2}>
                    <div className={styles.courseInterfaceWrapper}>
                        <h2 className={styles.courseTitle}>IELTS Intensive</h2>
                        <p className={styles.courseSubtitle}>Prepare for IELTS in 1 month</p>
                        <div className={styles.circleIcon}>
                            <span className={styles.circleText}>More Info</span>
                            <div className={styles.arrow}></div>
                        </div>
                    </div>
                    </div>

                    <div className={styles.courseBox + ' ' + styles.course3}>
                        <div className={styles.courseInterfaceWrapper}>
                        <h2 className={styles.courseTitle}>Mandarin Chinese</h2>
                        <p className={styles.courseSubtitle} style={{width: '40%'}}>Beginner to advanced with native teachers</p>
                        <div className={styles.circleIcon}>
                            <span className={styles.circleText}>More Info</span>
                            <div className={styles.arrow}></div>
                        </div>
                    </div>
                    </div>

                    <div className={styles.courseBox + ' ' + styles.course4}>
                    <div className={styles.courseInterfaceWrapper}>
                        <h2 className={styles.courseTitle}>HSK Course</h2>
                        <p className={styles.courseSubtitle}>Prepare for HSK levels 1-5</p>
                        <div className={styles.circleIcon}>
                            <span className={styles.circleText}>More Info</span>
                            <div className={styles.arrow}></div>
                        </div>
                        </div>
                    </div>
                </div>

                <p className={styles.discountInfo}>
                * Invite a friend to join 
                and get a 10% discount 
                for both of you 
                on your next purchase
                </p>
            </div>
        </div>
    )
}