import { useState } from 'react';
import styles from './Cases.module.css';
import BackgroundObject from './BackgroundObject';

export default function Cases() {
    const cases = [
        {
            id: 'Rue',
            photo: './assets/students/RuePhoto.jpg',
            certificate: './assets/students/RueCertificate.png',
            name: 'Rue Lin',
            bio: 'Rue came with a level of Upper-Intermediate, and left with an IELTS overall band 6.5, which helped her apply to study in Belgium.',
            highlights: ['IELTS 6.5', 'Went to Belgium']
        },

        {
            id: 'Jamil',
            photo: './assets/students/JamilPhoto.png',
            certificate: './assets/students/JamilCertificate.png',
            name: 'Jamil',
            bio: "Jamil's initial level was Intermediate. After 12 months of study he raised his level two steps higher to Advanced, completed our IELTS Intensive, and passed IELTS with 8.0. Now Jamil is studying a Bachelor of Engineering in Malaysia",
            highlights: ['IELTS 8.0', 'Studies in Malaysia']
        }
    ];

    const [caseIndex, setCaseIndex] = useState(0);

    // Defining event handlers
    const goBack = () => setCaseIndex(prevCaseIndex => prevCaseIndex - 1);
    const goForward = () => setCaseIndex(prevCaseIndex => prevCaseIndex + 1);

    // See if it's the first or the last index
    const isFirstCase = caseIndex === 0;
    const isLastCase = caseIndex === cases.length - 1;

    return (
        <div className={styles.casesSectionContainer}>
            <div className={styles.casesSectionContent}>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectStar} 
                        src={require('./assets/objects/star.png')} 
                        scrollFactor={-0.01}
                    />
                </div>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectPin} 
                        src={require('./assets/objects/pin.png')} 
                        scrollFactor={-0.01}
                    />
                </div>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject 
                        className={styles.bgObjectSpiral} 
                        src={require('./assets/objects/spiral.png')} 
                        scrollFactor={-0.01}
                    />
                </div>

                <h1 className={styles.casesTitle}>Student Cases:</h1>

                <div className={styles.caseBox}>
                    <div className={styles.caseBoxContent}>
                        <div className={styles.caseImgWrapper}>

                            <div className={styles.caseImg + ' ' + styles.casePhoto}
                            style={{backgroundImage: `url(${cases[caseIndex].photo})`,}}>
                            </div>

                            <div className={styles.caseImg + ' ' + styles.caseCertificate}
                            style={{backgroundImage: `url(${cases[caseIndex].certificate})`,}}>
                            </div>

                        </div>

                        <div className={styles.caseInfoWrapper}>
                            <h2 className={styles.caseName}>{cases[caseIndex].name}</h2>
                            <p className={styles.caseBio}>{cases[caseIndex].bio}</p>
                            
                            <div className={styles.caseHighlightsWrapper}>
                                {cases[caseIndex].highlights.map((item, index) => (
                                    <div key={index} className={styles.caseHighlight}>{item}</div>
                                ))}
                            </div>

                            <div className={styles.caseControls}>
                                <button 
                                onClick={goBack}
                                disabled={isFirstCase}
                                className={styles.caseButtonBack}
                                style={{backgroundColor: '#DBDBDB'}}
                                > 
                                    <div className={styles.arrowLeft}></div>
                                </button>
                                
                                <button 
                                onClick={goForward}
                                disabled={isLastCase}
                                className={styles.caseButtonForward}
                                style={{backgroundColor: '#DBDBDB'}}
                                >
                                    <div className={styles.arrowRight}></div>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}