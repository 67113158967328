import styles from './Reviews.module.css';
import BackgroundObject from './BackgroundObject';

export default function Reviews() {
    return (
        <div className={styles.reviewsSectionContainer}>
            <div className={styles.reviewsSectionContent}>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectSonic} 
                        src={require('./assets/objects/sonic.png')} 
                        scrollFactor={-0.02}
                    />
                </div>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectPasta} 
                        src={require('./assets/objects/pasta.png')} 
                        scrollFactor={-0.01}
                    />
                </div>

                <h1 className={styles.reviewsSectionTitle}>What Our Students Say</h1>
                <div className={styles.reviewsGrid}>
                    <div className={styles.reviewsLeft}>

                        <div className={styles.reviewsLeftTop}>

                            <div className={styles.speechBubble + ' ' + styles.reviewPercentage}>
                            <h2>96% of them</h2>
                            <div className={styles.starsWrapper}>
                                <div className={styles.bigStar}></div>
                                <div className={styles.littleStar}></div>
                            </div>
                            <p>
                                Are happy with the 
                                results after 12 lessons
                            </p>
                            </div>

                        <div className={styles.speechBubble + ' ' + styles.review1}>
                            <p>
                                The teacher is really keen on 
                                explaining grammar topics, 
                                that is why he is sharing his 
                                enthusiasm with others. 
                                All formulas of sentence structures 
                                were written on the whiteboard for we could 
                                rewrite them into the notebooks. 
                                It was also nice that he taught us some strategies, 
                                which are very useful in writing part.
                            </p>
                        </div>
                </div>

                <div className={styles.reviewsLeftBottom}>

                    <div className={styles.speechBubble + ' ' + styles.review2}>
                    <p>
                    Grammar was the most difficult 
                    and incomprehensible for me, 
                    but thanks to teacher Chase, 
                    I improved my knowledge of grammar 
                    and also understood a lot of things, 
                    it was very clear and also interesting, 
                    thank him very much, he is a wonderful teacher
                    </p>
                    </div>

                    <div className={styles.speechBubble + ' ' + styles.review3}>
                    <p>
                    I absolutely admire my writing teacher, 
                    because I've learned a lot of new information 
                    related to grammar, the basic structure of an essay, 
                    vocabulary and now I even know how to apply it. 
                    I really appreciate the opportunity to always 
                    ask any question ( even if it's a silly one) 
                    and get a comprehensive answer !! Amazing ;)
                    </p>
                    </div>

                </div>

            </div>

                <div className={styles.reviewsRight}>

                    <div className={styles.speechBubble + ' ' + styles.review4}>
                    <p>I adore Grammar and Writing lessons, 
                        teacher explains everything perfect, 
                        every lesson I learn something new, 
                        I ameliorate my grammar and writing skills, 
                        I also acquire new words for my vocabulary. 
                        About Listening and Reading. 
                        Teacher always makes us do our best. 
                        She always gives us lots of home task to make us 
                        understand topic better.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}