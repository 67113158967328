import Navbar from './Navbar';
import Start from './Start';
import Courses from './Courses';
import Cases from './Cases'
import Reviews from './Reviews';
import Teachers from './Teachers';

function App() {
  return (
    <div>
      <Navbar />
      <Start />
      <Courses />
      <Cases />
      <Reviews />
      <Teachers />
    </div>
  );
}

export default App;
