import React, { useState } from 'react';
import styles from './Start.module.css';
import faces from './assets/faces.gif';
import callInterface from './assets/callInterface.png';
import sign from './assets/sign.svg'
import BackgroundObject from './BackgroundObject';
import FreeLessonPopup from './FreeLessonPopup'

export default function Start() {

    // Handling the Pop-up Window
    const [showPopup, setShowPopup] = useState(false);
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <section className={styles.startSection}>
            <div className={styles.startSectionContent}>

            <div className={styles.left}>
                <div className={styles.regalia}>
                    <div><p>Students in 3 countries</p></div>
                    <div><p>Experienced Teachers</p></div>
                    <div><p>Digital Ecosystem</p></div>
                </div>
                <div className={styles.title}>
                    <h1>Speak Fluently with Chase!</h1>
                </div>
                <div className={styles.bulletPoints}>
                    <ul>
                        <li>Free lesson with a top expert</li>
                        <li>We will figure out how to break your specific language barrier</li>
                        <li>Create a personalized lesson plan for you</li>
                    </ul>
                </div>
            </div>

            <div className={styles.right}>
                <div className={styles.gifWrapper}>
                    <img className={styles.facesGif} src={faces} alt="Faces" />
                    <img className={styles.callInterface} src={callInterface} alt="CallInterface" />
                </div>

                <div className={styles.buttonContainer}>
                    <button onClick={togglePopup}>Free Lesson</button>
                </div>
            </div>

            <FreeLessonPopup showPopup={showPopup} togglePopup={togglePopup}/>

            </div>
        </section>
    );
}
